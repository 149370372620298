.calendar-view-date-cell {
    width: 150px;
    border-top: 1px solid white;
  }
  
  .calendar-view-date {
    width: 40px;
    font-size: 36px;
    line-height: 36px;
    margin-right: 10px;
  }
  
  .calendar-view-month {
    font-size: 0.75em;
  }
  
  .calendar-view-timespan {
    vertical-align: top;
    text-align: center;
    width: 180px;
    font-size: 1.25em;
  }
  
  .calendar-view-subject {
    font-size: 1.25em;
    width: 100%;
  }
  
  .calendar-view-organizer {
    font-size: .75em;
  }
  .tasks {
    border-top: 1px solid white;
    border-bottom: 1px solid white;
  }