html {
    height: 100%;
    width: 100%;
    margin: 0;
    padding: 0;
    /*font-family: sans-serif;*/
}
body {
    height: 100%;
    width: 100%;
    margin: 0;
    padding: 0;
    /*font-family: sans-serif;*/
}
.pic line {
	stroke: #FFFFFF
}
.pic rect {
	stroke: #FFFFFF
}
svg.full {
    width:100%;
    height:100%;
}
svg.absolute
{
    position: absolute;
}
g image {
    opacity: 0.4
}
g image {
    opacity: 1
}
#root {
    height: 100%;
    width: 100%;
    margin: 0;
    padding: 0;
    /*font-family: sans-serif;*/
}
